@font-face {
  font-family: Mallaire;
  src: url(./assets/Mallaire.otf);
}

.welcome .svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;

  margin-top: -20px;
}

.welcome svg {
  /* height: 500px; */
}

/* *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */

.welcome {
  font-size: 62.5%;
  /* --background-color: hsl(180, 20%, 90%); */
  --background-color: hsl(220, 10%, 17%);
  font-family: Mallaire;

  min-height: 100vh;
  padding: 20px;

  color: hsla(0, 0%, 0%, 0.6);
  background: var(--background-color);
  text-align: center;
}

.welcome h1 {
  position: relative;
  top: -100px;
  font-size: 32px;
  padding: 0;
  margin: 0;
  margin-bottom: -100px;
  background: -webkit-linear-gradient(
    25deg,
    hsl(27, 53%, 55%) 0%,
    hsl(29, 81%, 79%) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.welcome .main {
  position: relative;
  top: -200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.welcome .wrap {
  margin: 20px;

  transform-style: preserve-3d;
  transform: perspective(1000px);

  cursor: pointer;
}

.welcome .container {
  --rX: 0;
  --rY: 0;
  --bX: 50%;
  --bY: 80%;

  width: 300px;
  height: 360px;
  border: 1px solid var(--background-color);
  border-radius: 16px;
  /* padding-top: 10rem; */

  display: flex;
  align-items: center;
  /* flex-direction: column; */
  position: relative;
  transform: rotateX(calc(var(--rX) * 1deg)) rotateY(calc(var(--rY) * 1deg));

  background: hsl(212, 10%, 26%);
  background-position: var(--bX) var(--bY);
  background-size: 400px auto;
  box-shadow: 0 0 30px 5px hsla(0, 0%, 0%, 0.2);

  transition: transform 0.6s 1s;
}

.welcome .container-inactive {
  transform: none;
  filter: opacity(0.5) grayscale(70%);
}

.welcome .container-inner {
  margin: auto;
  width: 100%;
}

.welcome .container::before,
.welcome .container::after {
  content: "";

  width: 20px;
  height: 20px;
  border: 1px solid hsl(29, 81%, 79%);

  position: absolute;
  z-index: 2;

  opacity: 1;
  transition: 0.3s;
}

.welcome .container::before {
  top: 20px;
  right: 20px;

  border-radius: 20px;
  border-bottom-width: 0;
  border-left-width: 0;
}

.welcome .container::after {
  bottom: 20px;
  left: 20px;

  border-radius: 20px;
  border-top-width: 0;
  border-right-width: 0;
}

.welcome .container-inner::before,
.welcome .container-inner::after {
  content: "";

  width: 10px;
  height: 10px;
  border: 1px solid hsl(29, 81%, 79%);

  position: absolute;
  z-index: 3;

  opacity: 1;
  transition: 0.3s;
}

.welcome .container-inner::before {
  top: 10px;
  right: 10px;

  border-radius: 10px;
  border-bottom-width: 0;
  border-left-width: 0;
}

.welcome .container-inner::after {
  bottom: 10px;
  left: 10px;

  border-radius: 10px;
  border-top-width: 0;
  border-right-width: 0;
}

.welcome .container--active {
  transition: none;
}

.welcome .container--2 {
  background: linear-gradient(
    25deg,
    hsla(355, 80%, 42%, 0.7) 0%,
    hsla(2, 80%, 54%, 0.7) 100%
  );
}

/* .container--3 {
    filter: hue-rotate(160deg) saturate(140%);
  } */

.welcome .container p {
  /* color: hsla(0, 0%, 100%, 0.6); */
  font-size: 22px;
  width: 100%;
  background: -webkit-linear-gradient(
    25deg,
    hsl(27, 53%, 55%) 0%,
    hsl(29, 81%, 79%) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.welcome .wrap:hover .container::before,
.welcome .wrap:hover .container::after {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
}

.welcome .abs-site-link {
  position: fixed;
  bottom: 20px;
  left: 20px;
  color: hsla(0, 0%, 0%, 0.6);
  font-size: 16px;
}

.welcome footer {
  color: honeydew;
  font-family: Glory;
  font-weight: 100;
  margin-top: 100px;
  position: fixed;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%);
}

.welcome footer p {
  font-size: 15px;
}
