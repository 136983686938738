::placeholder {
  color: hsl(0, 0%, 45%);
  padding: 0 0.5rem;
}

$menu-height: 10rem;

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.board-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  perspective: 1000px;
  transform: translate(-50%, -50%) scale(0.6);
  transition: all 300ms ease-in-out;
  text-align: center;

  display: flex;
  // flex-direction: row;
  &-child {
    margin: auto 0.2rem;
    transform: rotateX(12deg);
    // width: 100%;
  }
}

.board-filter {
  filter: blur(10px);
}

#menu {
  position: sticky;
  z-index: 999;
  width: 30vw;
  margin: auto;
  top: 0;

  a,
  p {
    all: initial;
    margin: auto;
    margin-bottom: 0;
    font-size: 1.2rem;
    // color: hsl(212, 10%, 26%);
    color: black;
    font-weight: 500;
    font-family: Glory;
    // background-clip: initial;
    // text-fill-color:
  }
  a {
    font-weight: 500;
    // color: hsl(355, 80%, 42%);
    text-decoration: underline;
  }
  a:hover {
    opacity: 0.5;
  }
  .sectionButtons {
    cursor: pointer;
    color: #404040;
  }
  .sectionButtons:hover {
    opacity: 0.5;
  }

  .expand-menu {
    transform: translateY($menu-height - 1rem);
  }
  .element {
    flex-direction: column;
    transition: all 400ms ease-in-out;
    margin: 0;
    position: absolute;
    height: $menu-height;
    top: -$menu-height + 1rem;
    // width: 10rem;
    border: none;
    border-radius: 0 0 0.5rem 0.5rem;
    background: -webkit-linear-gradient(
      25deg,
      hsl(27, 53%, 55%) 0%,
      hsl(29, 81%, 79%) 100%
    );
  }
}

#status,
#rolldice {
  display: flex;
  margin: 0;
  margin-left: 2rem;
  h2 {
    // color: white;
    background: -webkit-linear-gradient(25deg, #c9864f 0%, #f5c89e 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    all: initial;
    margin: auto;
    margin-bottom: 0;
    font-size: 1.2rem;
    color: hsl(212, 10%, 26%);
  }
  .element {
    width: 10rem;
    height: 30rem;
    margin: 1rem;
    border: none;

    display: flex;
    flex-direction: column;

    // background: -webkit-linear-gradient(
    //   25deg,
    //   hsl(27, 53%, 55%) 0%,
    //   hsl(29, 81%, 79%) 100%
    // );

    &:hover {
      box-shadow: 0px 0px 2rem hsl(0, 0%, 10%);
    }

    .userNFT {
      display: grid;
      grid-gap: 0.7rem;
      // grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
      grid-template-columns: minmax(auto, 0.5fr) minmax(auto, 0.5fr);
      img {
        max-width: 100%;
      }
    }
  }
}
// .board {
// }

p {
  font-size: 1.2rem;
  font-weight: 500;
  background: -webkit-linear-gradient(
    25deg,
    hsl(27, 53%, 55%) 0%,
    hsl(29, 81%, 79%) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bigtext {
  font-size: 2.5rem;
  margin: 1rem 0;
}

button {
  display: flex;
  margin: auto;
  // padding: 1rem;
  cursor: pointer;

  border: 2px solid hsl(27, 53%, 55%);
  border-radius: 0.5rem;

  font-size: 1.2rem;
  font-weight: 500;
  font-family: Glory;

  color: hsl(212, 10%, 26%);
  background: -webkit-linear-gradient(
    25deg,
    hsl(27, 53%, 55%) 0%,
    hsl(29, 81%, 79%) 100%
  );
  &:hover {
    background: hsl(212, 10%, 26%);
    color: hsl(29, 81%, 79%);
  }
}

.dice {
  width: 3rem;
  height: 3rem;
  border: 2px solid transparent;
}

.dot {
  width: 12px;
  height: 12px;
}

#submit-bet {
  flex-direction: column;
  width: initial;
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  button {
    margin: 1rem;
  }

  // #modal-background {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   z-index: 1000;
  //   background: black;
  // }
  .prob {
    opacity: 0.5;
  }
  .input-bet {
    display: flex;
    align-items: center;
    border: 3px solid hsl(27, 53%, 55%);
    border-radius: 0.5rem;
    img {
      width: 2rem;
      height: 2rem;
    }
    input {
      color: white;
      font-size: 1rem;
      font-weight: 600;
      text-align: right;
      padding-right: 0.5rem;
      background: transparent;
      padding: 0.5rem;
      border: none;
    }
  }
}

.element {
  width: 100%;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  // background: linear-gradient(
  //     25deg,
  //     hsl(355, 80%, 42%) 0%,
  //     hsl(2, 80%, 54%) 100%
  //     );
  // background: hsl(224, 8%, 26%);
  background: hsl(212, 10%, 26%);

  margin: 1rem 0.2rem;
  padding: 1rem;
  border: 2px solid hsl(27, 53%, 55%);
  border-radius: 0.5rem;
  box-shadow: 0px 0px 2rem hsl(0, 0%, 10%);
  transition: all 400ms ease-in-out;
  &:hover {
    // opacity: 0.5;
    box-shadow: 0 0 30px hsl(29, 81%, 79%) inset;
    // border: 3px solid hsl(27, 53%, 55%);
  }
}

.row-one,
.row-two,
.row-three,
.row-four {
  display: flex;
  justify-content: space-between;

  margin: auto;

  .element {
    p {
      font-size: 2rem;
      font-weight: 300;
      text-align: center;
      margin: auto;
      padding: 0rem 1rem;
      // -webkit-text-stroke: 1px hsl(29, 81%, 79%);
    }
  }
}

.row-two,
.row-three,
.row-four {
  .element {
    flex-direction: column;
    // padding: 1rem 0.5rem;
    // background: linear-gradient(
    //   25deg,
    //   hsl(355, 80%, 42%) 0%,
    //   hsl(2, 80%, 54%) 100%
    // );

    p {
      color: hsl(212, 10%, 26%);
      font-size: 0.9rem;
      font-weight: 500;
      padding: 1rem 0rem;
    }
  }
}

.row-three {
  .element {
    p {
      // font-size: 1rem;
      padding: 0px;
    }
    p:first-of-type {
      font-size: 3rem;
      padding: 0.5rem 0rem 1rem 0rem;
    }
  }
}

.row-four {
  .col {
    // margin: auto;
    // width: 100%;
    .element {
      width: auto;

      &-center {
        padding: 0.2rem 2rem;
      }
    }
    .row-inner {
      display: flex;
      flex-direction: row;

      p {
        margin: 0.2rem 0;
        padding: 0;
      }
      .dice {
        transform: scale(0.4);
        margin: -0.65rem;
      }
    }

    &-two {
      display: flex;
      flex-direction: column;
      .element {
        flex-direction: row;
        padding: 0;
        margin: auto;
        .dice {
          transform: scale(0.6);
          margin: 0;
          // width: 2rem;
          // height: 2rem;
        }
      }
    }
  }
  .inner-col {
    display: flex;

    .element {
      padding: 1.7rem 1rem;
    }
  }
}

.platinum {
  box-shadow: 0 0 20px hsl(0, 0%, 96%) inset;

  .dice {
    border: 2px solid hsl(27, 53%, 55%);
    background: linear-gradient(
      25deg,
      hsl(0, 0%, 79%) 0%,
      hsl(0, 0%, 96%) 100%
    );
  }
  // p {
  //   background: initial;
  //   -webkit-background-clip: initial;
  //   -webkit-text-fill-color: initial;

  //   color: hsl(0, 0%, 96%) !important;
  // }
}

.sapphire {
  box-shadow: 0 0 20px hsl(218, 80%, 54%) inset;

  .dice {
    border: 2px solid hsl(27, 53%, 55%);
    background: linear-gradient(
      25deg,
      hsl(228, 80%, 42%) 0%,
      hsl(218, 80%, 54%) 100%
    );
  }
}

.ruby {
  box-shadow: 0 0 20px hsl(2, 80%, 54%) inset;

  .dice {
    border: 2px solid hsl(27, 53%, 55%);
    background: linear-gradient(
      25deg,
      hsl(355, 80%, 42%) 0%,
      hsl(2, 80%, 54%) 100%
    );
  }
}

.emerald {
  box-shadow: 0 0 20px hsl(95, 68%, 46%) inset;

  .dice {
    border: 2px solid hsl(27, 53%, 55%);
    background: linear-gradient(
      25deg,
      hsl(115, 81%, 22%) 0%,
      hsl(95, 68%, 46%) 100%
    );
  }
}

.amethyst {
  box-shadow: 0 0 20px hsl(294, 100%, 50%) inset;

  .dice {
    border: 2px solid hsl(27, 53%, 55%);
    background: linear-gradient(
      25deg,
      hsl(276, 85%, 42%) 0%,
      hsl(294, 100%, 50%) 100%
    );
  }
}

.obsidian {
  box-shadow: 0 0 20px hsl(0, 0%, 52%) inset;

  .dice {
    border: 2px solid hsl(27, 53%, 55%);
    background: linear-gradient(
      25deg,
      hsl(0, 0%, 19%) 0%,
      hsl(0, 0%, 52%) 100%
    );
  }

  .dot {
    background: linear-gradient(
      200deg,
      hsl(0, 0%, 53%) 0%,
      hsl(0, 0%, 70%) 100%
    );
  }
}

.citrine {
  box-shadow: 0 0 20px hsl(75, 80%, 54%) inset;

  .dice {
    border: 2px solid hsl(27, 53%, 55%);
    background: linear-gradient(
      25deg,
      hsl(25, 100%, 54%) 0%,
      hsl(75, 80%, 54%) 100%
    );
  }

  .dot {
    background: linear-gradient(
      200deg,
      hsl(0, 0%, 5%) 0%,
      hsl(0, 0%, 40%) 100%
    );
  }
}

.diamond {
  box-shadow: 0 0 20px hsl(164, 100%, 54%) inset;

  .dice {
    border: 2px solid hsl(27, 53%, 55%);
    background: linear-gradient(
      25deg,
      hsl(164, 100%, 54%) 0%,
      hsl(218, 80%, 54%) 100%
    );
  }
}

//Dice

#rolldice {
  flex-direction: column;
  .element {
    height: auto;

    background: -webkit-linear-gradient(25deg, #c9864f 0%, #f5c89e 100%);

    .faDice {
      // background: linear-gradient(
      //   20deg,
      //   hsl(0, 0%, 30%) 0%,
      //   hsl(0, 0%, 40%) 100%
      // );
      color: #3c4249;

      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;

      -webkit-text-stroke: 0px transparent;
      font-size: 4rem;

      padding: 0.25rem;
      text-shadow: 1px 1px 5px #c9864f, -1px -1px 5px #c9864f;
    }

    button {
      border: 3px solid #3c4249;
      font-weight: 600;
      margin: 1rem auto;
      padding: 1rem;
    }
  }
}

.shaking {
  animation-name: wobble;
  animation-duration: 1s;
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-45%, 0, 0) rotate3d(0, 0, 1, -90deg);
  }

  30% {
    transform: translate3d(40%, 0, 0) rotate3d(0, 0, 1, 90deg);
  }

  45% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -45deg);
  }

  60% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 45deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.lose {
  animation: LOSER 0.5s ease-in-out;
}

@keyframes LOSER {
  0%,
  33% {
    box-shadow: 0 0 50px hsl(355, 80%, 42%) inset, 0 0 10px hsl(355, 80%, 42%);
  }
  34%,
  67% {
    box-shadow: none;
  }
  68%,
  100% {
    box-shadow: 0 0 50px hsl(355, 80%, 42%) inset, 0 0 10px hsl(355, 80%, 42%);
  }
}
